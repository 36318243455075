// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {onInput} from '@github-ui/onfocus'
import {validate} from '../behaviors/html-validation'

on('focusin', '.js-structured-issue-textarea-expander', function ({currentTarget}) {
  resetTextareas()
  appendAndSyncWysiwyg(currentTarget as HTMLInputElement)
})

onInput('.comment-form-textarea', function ({currentTarget}) {
  syncInputValues(currentTarget as HTMLInputElement)
  const form = document.querySelector<HTMLFormElement>('.new_issue')
  if (!form) return
  validate(form)
})

onInput('.js-comment-field', function ({currentTarget}) {
  syncInputValues(currentTarget as HTMLInputElement)
})

// This is so that uploads persist when switching between textareas
on('change', '.js-comment-field', function ({currentTarget}) {
  syncInputValues(currentTarget as HTMLInputElement)
  const form = document.querySelector<HTMLFormElement>('.new_issue')
  if (!form) return
  validate(form)
})

function appendAndSyncWysiwyg(currentTarget: HTMLInputElement) {
  styleMarkdownPreview()
  const wysiwygComponent = document.querySelector<HTMLElement>('.js-wysiwyg-container')
  const formContainer = currentTarget.closest<HTMLElement>('.form-group-body')
  if (!formContainer || !wysiwygComponent) return

  const targetContainer = formContainer.querySelector<HTMLElement>('.js-wysiwyg-target')
  if (targetContainer) {
    targetContainer.appendChild(wysiwygComponent)
    wysiwygComponent.hidden = false
    const wysiwygInput = formContainer.querySelector<HTMLInputElement>('.comment-form-textarea, .CommentBox-input')!
    wysiwygInput.value = currentTarget.value

    const placeholderElement = wysiwygComponent.querySelector<HTMLElement>('[data-comment-box-placeholder]')

    if (placeholderElement) {
      // the new comment box uses a custom element for the placeholder text
      placeholderElement.textContent = currentTarget.placeholder
    } else {
      // otherwise use the native placeholder attribute for the legacy comment box
      wysiwygInput.placeholder = currentTarget.placeholder
    }

    currentTarget.hidden = true

    // reset "Write" mode when clicking away from "Preview" mode on another textarea
    formContainer.querySelector<HTMLElement>('.js-write-tab')!.click()

    wysiwygInput.focus()
  }
}

function syncInputValues(currentTarget: HTMLInputElement) {
  const formContainer = currentTarget.closest<HTMLElement>('.form-group-body')
  if (!formContainer) return

  const textarea = formContainer.querySelector<HTMLInputElement>('.js-structured-issue-textarea-expander')
  if (!textarea) return
  textarea.value = currentTarget.value
}

function resetTextareas() {
  const allTextareas = document.querySelectorAll<HTMLElement>('.js-structured-issue-textarea-expander')
  for (const textarea of allTextareas) {
    textarea.hidden = false
  }
}

function styleMarkdownPreview() {
  // this removes an inside border which looks cosmetically weird
  const markdownPreview = document.querySelector<HTMLElement>('.js-preview-panel')
  if (markdownPreview && markdownPreview.classList.contains('border-bottom')) {
    markdownPreview.classList.remove('border-bottom')
  }
}
